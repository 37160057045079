<template>

    <div class='list_content'>
        <h2 style="text-align: center;">修改用户</h2>
        <el-form ref="form" :model="user" label-width="100px">
            <el-form-item label="*用户名" style="margin-left: 0px;">
                <el-tooltip class="item" effect="dark" content="用户登录的账号，一般为客户的单位简称" placement="right">
                    <div style="width:200px;background-color:silver;padding-left:20px">{{ user.user_name }}</div>
                </el-tooltip>
            </el-form-item>

            <el-form-item label="修改密码">
                <el-input v-model="user.old_pwd" placeholder=""></el-input>
            </el-form-item>

            <el-form-item class="chts" label="*剩余表单">
                <p style="width:100px;background-color:silver;padding-left:20px">{{ user.pay_num - user.allot_num }}</p>
            </el-form-item>

            <el-form-item class="chts" v-if="cztsShow" label="*新增表单">
                <el-input v-model="addNum" placeholder="充多少就写多少"></el-input>
            </el-form-item>

            <el-form-item class="chts" label="*剩余短信">
                <p style="width:100px;background-color:silver;padding-left:20px">{{ user.msg_pay_num }}</p>
            </el-form-item>

            <el-form-item class="chts" v-if="cztsShow" label="*新增短信">
                <el-input v-model="addMsgNum" placeholder="充多少就写多少"></el-input>
            </el-form-item>

            <el-form-item v-if="cztsShow" label="*运营状态">
                <el-select v-model="user.yunying_state" placeholder="请选择">
                    <el-option label="1=渠道" value=1></el-option>
                    <el-option label="2=自运营" value=2></el-option>
                    <el-option label="3=合作" value=3></el-option>
                    <el-option label="4=渠转自" value=4></el-option>
                    <el-option label="5=自转渠" value=5></el-option>
                </el-select>
            </el-form-item>

            <el-form-item v-if="cztsShow" label="*来源">
                <el-select v-model="user.app_name" placeholder="请选择">
                    <el-option label="字节-抖音" value="字节-抖音"></el-option>
                    <el-option label="字节-今日头条" value="字节-今日头条"></el-option>
                    <el-option label="微信" value="微信"></el-option>
                    <el-option label="快手" value="快手"></el-option>
                    <el-option label="百度" value="百度"></el-option>
                    <el-option label="小红书" value="小红书"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item v-if="cztsShow" label="*单位名称" width="20px">
                <el-input v-model="user.comp_name"></el-input>
            </el-form-item>
            <el-form-item v-if="cztsShow" label="*科室类型" width="120px">
                <el-input v-model="user.ks_type"></el-input>
            </el-form-item>

            <el-form-item v-if="cztsShow" label="*省份">
                <el-input v-model="user.province_name"></el-input>
            </el-form-item>

            <el-form-item v-if="cztsShow" label="*城市">
                <el-input v-model="user.city_name"></el-input>
            </el-form-item>

            <el-form-item v-if="cztsShow" label="*手机号">
                <el-input v-model="user.phone"></el-input>
            </el-form-item>

            <el-form-item v-if="cztsShow" label="备注">
                <el-input v-model="user.remark"></el-input>
            </el-form-item>

            <el-button type="primary" @click="onSubmit">确认</el-button>
            <el-button @click="goback">取消</el-button>
        </el-form>
    </div>

</template>

<script>
import LeftMenu from '@/components/left.vue'
import HeaderTop from '@/components/top.vue'
import Footer from '@/components/footer.vue'

export default {
    name: 'UserInfoView',
    // 注册组件
    components: {
        LeftMenu,
        HeaderTop,
        Footer
    },
    props: {},
    // data() 为变量赋值等
    data() {
        return {
            user_id: 0,
            cache_user: {},
            user: {},
            jbShow: false,
            yhztShow: false,
            cztsShow: false,
            passwordShow: false,
            addNum: 0,
            newPassword: "",
            addMsgNum: 0,
        }
    },

    created() {
        // 从参数获取userid
        this.user_id = this.$route.query.user_id
        // 从缓存获取user
        this.cache_user = JSON.parse(sessionStorage.getItem('user'))
        this.onload()
    },

    methods: {
        onload() {
            let that = this
            if (that.cache_user.user_level == 1 || that.cache_user.user_level == 2) {
                that.jbShow = true
                that.yhztShow = true
                that.cztsShow = true
                that.passwordShow = true
            }

            let req = {
                user_id: Number(that.user_id)
            }
            this.myAxios.post(that.myBaseurl + '/user/info', req)
                .then(function (res) {
                    if (res.data.Code === '200') {
                        console.log("userInfo", res.data.Data);
                        that.user = res.data.Data
                    } else {
                        alert("获取用户信息失败!")
                    }
                })
        },

        onSubmit() {
            console.log('开始修改!');
            let that = this
            that.user.man_level = Number(that.cache_user.user_level)
            that.user.yunying_state = Number(that.user.yunying_state)
            if (that.addNum != 0) {
                that.user.pay_num = Number(that.user.pay_num) + Number(that.addNum)
            }

            if (that.addMsgNum != 0) {
                that.user.msg_pay_num = Number(that.user.msg_pay_num) + Number(that.addMsgNum)
            }

            if (that.newPassword != "") {
                that.user.password = that.newPassword
                var pwdRegex = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z]).{6,30}');

                if (!pwdRegex.test(that.user.password)) {
                    alert("您的密码复杂度太低（密码中必须包含字母、数字,最少6位),请及时修改密码!");
                    return
                }
            }
            this.myAxios.post(that.myBaseurl + '/user/update', that.user)
                .then(function (res) {
                    if (res.data.Code === '200') {
                        alert("修改成功 !")
                        that.$router.push({
                            path: '/user/list',
                        })
                    } else {
                        alert(res.data.Msg)
                        return
                    }
                })
        },

        goback() {
            history.go(-1)
        }
    }
}

</script>

<style scoped="scoped">
/*大框体样式*/
.el-main {
    height: calc(100vh - 120px);
}

.list_content {
    max-width: 800px;
}

.el-form-item__label {
    max-width: 200px !important;
}

.chts {
    width: 220px !important;
}
</style>
